@mixin background-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@mixin serif-font() {
  font-family: "Lora", "Times New Roman", serif;
}
@mixin sans-serif-font() {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
